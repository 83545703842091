'use client'

import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useState,
} from 'react'

export type MarketingStateProps = {
  showModal: boolean
}

type MarketingContextProps = {
  state: MarketingStateProps
  setState: Dispatch<SetStateAction<MarketingStateProps>>
}

const initialMarketingState: MarketingStateProps = {
  showModal: false,
}

export const MarketingContext = createContext<MarketingContextProps>({
  state: initialMarketingState,
  setState: () => {},
})

export const MarketingProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState(initialMarketingState)

  return (
    <MarketingContext.Provider value={{ state, setState }}>
      {children}
    </MarketingContext.Provider>
  )
}
